body {
  font-family: "Inter", sans-serif;
}

button.gm-ui-hover-effect {
  display: none !important;
}

.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 1200px;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 1200px;
  border: none;
}

.google-map-container {
  width: 33%;
  height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #E6F9FD;
}

.google-map-container-list {
  width: 67%;
  height: calc(100vh - 300px);
}

.responsive-list {
  width: 33%;
}

.sidebar-status {
  display: block;
}

.data-total-info {
  display: flex;
  flex-direction: row;
}

.data-box-info {
  width: calc((100%) / 3);
}

.driver-filter {
  display: flex;
  flex-direction: row;
}

.date-selector {
  width: 170px;
}

.date-selector-input {
  width: 90%;
}

@media (min-width: 1000px) {
  .driver-selector {
    width: 15vw;
  }

  .pc-view-date-range-picker {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
      rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
      rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    border-radius: 4px;
  }
}

@media (max-width: 600px) {
  .responsive-box {
    flex-direction: column;
  }

  .app-container {
    position: relative;
  }

  .blur-background {
    filter: blur(5px);
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    z-index: 1000;
    /* Ensure it's behind the popup but above the rest */
  }

  .driver-filter {
    flex-direction: column;
  }

  .data-total-info {
    flex-direction: column;
  }

  .google-map-container {
    width: 100%;
    height: 100%;
  }

  .google-map-container-list {
    width: 100%;
  }

  .responsive-list {
    width: 100%,
  }

  .driver-selector {
    width: 80vw;
  }

  .sidebar-status {
    display: none;
  }

  .data-box-info {
    width: 100%;
  }

  .date-selector {
    width: 80vw;
  }
}

@media print {
  .hide-on-screen {
    display: block !important;
  }
}

@media screen {
  .hide-on-screen {
    display: none;
  }
}